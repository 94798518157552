import { Fragment } from 'react';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import { useStyles } from "../common/appStyles";

export default function HomePage() {
    const classes = useStyles();

    return (
        <Fragment>
            <Grid
                container
                justifyContent={'center'}
                spacing={1}
                className={classes.container}
            >
                <Grid item sm={12}>
                    <h2>Welcome to ShoForms... Your custom home page here...</h2>
                </Grid>
            </Grid>
        </Fragment>
    );
}