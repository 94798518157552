import ShoFormsClient from '../httpClient/ShoFormsClient';
import indexedDBService from '../services/indexdb-service';
import { appConfig } from './config.service';
import { showSnackbar } from './snackbar.service';
import { Network } from '@capacitor/network';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { IDownloadFormPdfRequest, IForm, IFormDataDeleteRequest, IFormDataFileRequest, IFormDataFileResponse, IFormDataListRequest, IFormDataListResponse, IFormListRequest, IFormListResponse, ISaveFormDataRequest, IUploadCloudFileRequest, IUploadCloudFileResponse } from '../types/forms'
import { Capacitor } from '@capacitor/core';
import { FileOpener, FileOpenerOptions } from '@capacitor-community/file-opener';

export const GetFormDetailList = async (request: IFormListRequest) => {
    try {
        const instance = ShoFormsClient();
        let response: IFormListResponse = (await instance.post('/Form/GetFormDetailList', request)).data;

        // Store response to index db
        await indexedDBService.setItem('General', 'GetFormDetailList', response.formDetailList);
        const formList: IForm[] = response.formDetailList
        if (formList) {
            formList.forEach(formDetail => {
                const layoutSchema = JSON.parse(formDetail.formLayoutSchemaJson);
                if (layoutSchema && layoutSchema.elements) {
                    layoutSchema.elements.forEach(layout => {
                        layout.elements.forEach(element => {
                            if (element.options && element.options.asyncEnum) {
                                if (element.options.asyncEnum.excelUrl) {
                                    GetFromExcel(element.options.asyncEnum);
                                } else {
                                    GetMasters(element.options.asyncEnum);
                                }
                            }
                        });
                    });
                }
            });
            return formList;
        }
    } catch (error) {
        if (!navigator.onLine) {
            const indexStorageData = await indexedDBService.getItem('General', 'GetFormDetailList');
            return indexStorageData || [];
        } else {
            throw error;
        }
    }
};

export const GetFormDataList = async (request: IFormDataListRequest) => {
    try {
        const instance = ShoFormsClient();
        let response: IFormDataListResponse = (await instance.post('/Form/GetFormDataList', request)).data;

        return response;
    } catch (error) {
        throw error;
    }
};

export const GetFormDataDetail = async (request) => {
    try {
        const instance = ShoFormsClient();
        let response = await instance.post('/Form/GetFormDataDetail', request);
        return response.data.formDataDetail;
    } catch (error) {
        // if (!navigator.onLine) {
        //     const storedData = await indexedDBService.getItem('Sync', 'AddUpdateFormData');
        //     const formDataDetail = storedData.find(x => x.data.customerId === request?.customerId && x.data.formDataId == request.formDataId);
        //     
        //     return formDataDetail;
        // }
        throw error;
    }
};

export const GetFormDataFile = async (request: IFormDataFileRequest) => {
    try {
        const instance = ShoFormsClient();
        let response: IFormDataFileResponse = (await instance.post('/Form/GetFormDataFile', request)).data;

        return response;
    } catch (error) {
        // if (!navigator.onLine) {
        //     const storedData = await indexedDBService.getItem('Sync', 'AddUpdateFormData');
        //     const formDataDetail = storedData.find(x => x.data.customerId === request?.customerId && x.data.formDataId == request.formDataId);
        //     
        //     return formDataDetail;
        // }
        throw error;
    }
};

export const GetFormSchemaDetail = async (request) => {
    try {
        const instance = ShoFormsClient();
        return await instance.post('/Form/GetFormSchemaDetail', request);
    } catch (error) {
        throw error;
    }
};

export const SaveFormDetail = async (request) => {
    try {
        const instance = ShoFormsClient();
        return await instance.post('/Form/SaveFormDetail', request);
    } catch (error) {
        throw error;
    }
};


export const GetFormList = async (request) => {
    try {
        const instance = ShoFormsClient();
        var response = await instance.post('/Form/GetFormList', request);
        return response.data.formList;
    } catch (error) {
        throw error;
    }
};


export const GetFormSetting = async (request) => {
    try {
        const instance = ShoFormsClient();
        return await instance.post('/Form/GetFormSetting', request);
    } catch (error) {
        throw error;
    }
};

export const SaveFormSetting = async (request) => {
    try {
        const instance = ShoFormsClient();
        return await instance.post('/Form/SaveFormSetting', request);
    } catch (error) {
        throw error;
    }
};


export const GetMasters = async (request: { url: string; method: string; }) => {
    try {
        const instance = ShoFormsClient();
        let response;
        if (request.method.toUpperCase() === "GET")
            response = await instance.get(request.url);
        if (request.method.toUpperCase() === "POST")
            response = await instance.post(request.url);

        await indexedDBService.setItem("Master", request.url, response.data);

        return response.data;
    } catch (error) {
        if (!navigator.onLine) {
            const indexStorageData = await indexedDBService.getItem("Master", request.url);
            return indexStorageData || [];
        } else {
            throw error;
        }
    }
};


export const GetFromExcel = async (request: { url: string; excelUrl: string; }) => {
    try {
        const instance = ShoFormsClient();
        let response = await instance.post(request.url, { url: request.excelUrl });

        await indexedDBService.setItem("Master", request.excelUrl, response.data);

        return response.data;
    } catch (error) {

        const status = await Network.getStatus();

        if (!navigator.onLine || !status.connected) {
            const indexStorageData = await indexedDBService.getItem("Master", request.url);
            return indexStorageData || [];
        } else {
            throw error;
        }
    }
};

export const UploadCloudFile = async (request: IUploadCloudFileRequest) => {
    try {
        const instance = ShoFormsClient();
        let response: IUploadCloudFileResponse = (await instance.post('/Form/UploadCloudFile', request)).data;
        return response;
    } catch (error) {
        console.error('UploadCloudFile', error)
    }
};

export const RemoveRowFromIndexDbAddUpdateFormDataStoreViaUniqueId = async (uniqueId: number) => {
    const storedData = await indexedDBService.getItem('Sync', 'AddUpdateFormData');
    if (storedData) {
        const removeIndex = storedData.findIndex(x => x.data.uniqueId === uniqueId);
        storedData.splice(removeIndex, 1);
        await indexedDBService.updateItem('Sync', 'AddUpdateFormData', storedData);
        const channel = new BroadcastChannel('sho-forms-channel');
        channel.postMessage(storedData?.length);
    }
}

export const DeleteFormData = async (request: IFormDataDeleteRequest) => {
    try {
        console.log('request.uniqueId', request.uniqueId)
        if (request.uniqueId) {
            await RemoveRowFromIndexDbAddUpdateFormDataStoreViaUniqueId(request.uniqueId);
        }
        console.log('request.formDataId', request.formDataId)
        if (request.formDataId) {
            const instance = ShoFormsClient();
            await instance.post('/Form/DeleteFormData', request);
        }
    } catch (error) {
        console.error('DeleteFormData', error)
    }
};

const testConnectionSpeed = async (): Promise<string> => {
    // Initial check using the Network Information API
    const getConnectionType = (): string | null => {
        const connection = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;
        return connection?.effectiveType || null;
    };

    const connectionType = getConnectionType();

    // Early return for slow connections without using non-null assertion operator
    if (connectionType && ['slow-2g', '2g', '3g'].includes(connectionType)) {
        return 'slow';
    }

    // Test API call speed if connection is not known to be slow
    try {
        const start = performance.now();
        const instance = ShoFormsClient(); // Assuming ShoFormsClient() is correctly defined elsewhere
        await instance.post('/Form/TestApiPresence');
        const duration = performance.now() - start;
        return duration > 1000 ? 'slow' : 'fast';
    } catch (error) {
        return 'unknown';
    }
};

export const AddUpdateFormData = async (request: ISaveFormDataRequest) => {
    try {
        if (request.saveAsDraftToDevice) {

            await StoreFormDataOnDevice(request)
        }
        else {

            let connectionSpeed = await testConnectionSpeed();

            if (connectionSpeed === "fast") {
                const instance = ShoFormsClient();
                await instance.post('/Form/AddUpdateFormData', request);
                if (request.uniqueId) {
                    await RemoveRowFromIndexDbAddUpdateFormDataStoreViaUniqueId(request.uniqueId);
                }
            }
            else {
                showSnackbar("Slow internet speed detected. Storing on device.", "error");
                await StoreFormDataOnDevice(request)
            }
        }

        return true;
    } catch (error) {
        const status = await Network.getStatus();

        if (!navigator.onLine || !status.connected) {
            showSnackbar("Error. Slow internet speed detected. Storing on device.", "error");
            await StoreFormDataOnDevice(request)
        } else {
            throw error;
        }
    }
};

export const StoreFormDataOnDevice = async (request: ISaveFormDataRequest) => {

    let storedData = await indexedDBService.getItem('Sync', 'AddUpdateFormData');

    if (!storedData) {
        await indexedDBService.setItem('Sync', 'AddUpdateFormData', []);
    }

    storedData = await indexedDBService.getItem('Sync', 'AddUpdateFormData');

    let body = storedData.find(x => x.data.uniqueId === request.uniqueId);

    if (body) {
        body.data = request;
        await indexedDBService.updateItem('Sync', 'AddUpdateFormData', storedData);
    }
    else {
        let body = {
            data: request,
            path: 'Form/AddUpdateFormData',
            type: 'POST'
        };

        const updatedData = [...storedData, body];
        await indexedDBService.updateItem('Sync', 'AddUpdateFormData', updatedData);
    }

    const channel = new BroadcastChannel('sho-forms-channel');
    channel.postMessage(storedData?.length);

    return true;
};

export const DownloadFormPdf = async (request: IDownloadFormPdfRequest) => {
    return new Promise((resolve, reject) => {
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = async () => {
            if (xhttp.readyState === 4) {
                if (xhttp.status === 200) {
                    if (xhttp.response.size === 0) {
                        showSnackbar("No data to download", "error");
                        resolve(false);
                        return;
                    }

                    const blob = xhttp.response;
                    const fileName = request.formFileName + '.pdf';

                    if (Capacitor.isNativePlatform()) {
                        // Handle file download on Capacitor device
                        const reader = new FileReader();
                        reader.onloadend = async () => {
                            let base64data = reader.result?.toString();
                            if (base64data) {
                                base64data = base64data.split(',')[1]; // Remove the prefix
                                try {
                                    const writeResult = await Filesystem.writeFile({
                                        path: fileName,
                                        data: base64data,
                                        directory: Directory.Data
                                    });
                                    showSnackbar("File downloaded successfully", "success");

                                    const filePath = writeResult.uri;

                                    const fileOpenerOptions: FileOpenerOptions = {
                                        filePath: filePath,
                                        contentType: 'application/pdf',
                                        openWithDefault: true,
                                    };
                                    await FileOpener.open(fileOpenerOptions).then(() => {
                                        console.log('File is opened');
                                    }).catch(error => {
                                        console.error('Error opening file', error);
                                    });
                                } catch (e) {
                                    console.error('Filesystem.writeFile error', e);
                                    showSnackbar("File download failed", "error");
                                    reject(e);
                                }
                            }
                            resolve(true);
                        };
                        reader.readAsDataURL(blob);
                    } else {
                        // Handle file download in web
                        const a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = fileName;
                        a.style.display = 'none';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        resolve(true);
                    }
                } else {
                    reject(xhttp.statusText);
                }
            }
        };

        let token = localStorage.getItem('auth0token');

        // Post data to URL which handles post request
        const apiPath = appConfig.API_URL + 'Form/DownloadFormPdf';
        xhttp.open('POST', apiPath);
        xhttp.setRequestHeader('Content-Type', 'application/json');
        xhttp.setRequestHeader('Authorization', `Bearer ` + token);
        // You should set responseType as blob for binary responses
        xhttp.responseType = 'blob';
        xhttp.send(JSON.stringify(request));
    }).then(() => {
        // Adding a delay of 1 second to ensure loader visibility
        return new Promise(resolve => setTimeout(resolve, 1000));
    });
};

export const DownloadExcel = async (customerId, formId) => {
    try {
        const instance = ShoFormsClient();
        let params: string = `?customerId=${customerId}&formId=${formId}`;
        let result = await instance.get(`/Form/GenerateExcel${params}`, { responseType: 'json' });

        const byteCharacters = atob(result.data.fileContents);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = result.data.fileDownloadName;

        if (Capacitor.isNativePlatform()) {
            // Handle file download on Capacitor device
            const reader = new FileReader();
            reader.onloadend = async () => {
                let base64data = reader.result?.toString();
                if (base64data) {
                    base64data = base64data.split(',')[1]; // Remove the prefix
                    try {
                        const writeResult = await Filesystem.writeFile({
                            path: fileName,
                            data: base64data,
                            directory: Directory.Data
                        });
                        showSnackbar("File downloaded successfully", "success");

                        const filePath = writeResult.uri;

                        const fileOpenerOptions: FileOpenerOptions = {
                            filePath: filePath,
                            contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            openWithDefault: true,
                        };
                        await FileOpener.open(fileOpenerOptions).then(() => {
                            console.log('File is opened');
                        }).catch(error => {
                            console.error('Error opening file', error);
                        });
                    } catch (e) {
                        console.error('Filesystem.writeFile error', e);
                        showSnackbar("File download failed", "error");
                    }
                }
            };
            reader.readAsDataURL(blob);
        } else {
            // Handle file download in web
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    } catch (error) {
        throw error;
    }
};

export const DownloadFileZipFromExcelRedirect = async (formDataId, fieldName) => {
    try {
        const instance = ShoFormsClient();
        let params: string = `?formDataId=${formDataId}&fieldName=${fieldName}`
        let result = await instance.get(`/Form/GetFilesZip${params}`, { responseType: 'json' });
        const a = document.createElement('a');
        var byteCharacters = atob(result.data.fileContents);
        var byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: 'application/zip' });
        a.href = window.URL.createObjectURL(blob);
        a.download = result.data.fileDownloadName;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove();
    } catch (error) {

        throw error;
    }
};

