import React, { ComponentType, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../store/userSlice';
import { updateRoles } from '../../store/rolesSlice';
import { updateUserResponse } from '../../store/userResponseSlice'; // Import action creator
import { updateBranding } from '../../store/brandingSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { IBranding, IUser } from '../../types/reduxStore';
import { IUserResponse } from '../../types/reduxStore';
import { ICustomer } from '../../types/customer';
import { GetUser, GetUserPageAccess } from '../../services/user-service';
import { GetCustomer, GetCustomerLogo } from '../../services/customer.service';
import { IUserPageAccessResponse } from '../../types/manage';
import indexedDBService from '../../services/indexdb-service';
import { ShoFormLoader } from '../../components/main-layout/ShoFormLoader';

interface WithProtectedRouteProps {
    redirectTo?: string;
}

const withProtectedRoute = <P extends object>(
    WrappedComponent: ComponentType<P>,
    options?: WithProtectedRouteProps
) => {
    const { redirectTo = '/login' } = options || {};

    return (props: P) => {
        const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
        const dispatch = useDispatch();
        const navigate = useNavigate();

        const userDetails = useSelector((state: RootState) => state.userDetail);

        const initApplication = async (token) => {
            if (user && user.nickname && user.name && user.picture && user.updated_at && user.email) {
                const userState: IUser = {
                    nickname: user.nickname,
                    name: user.name,
                    picture: user.picture,
                    updated_at: user.updated_at,
                    email: user.email,
                };
                dispatch(updateUser(userState));
                await indexedDBService.setItem('User', 'updateUser', user);
            }

            localStorage.setItem('auth0token', token);
            if (user && user.email) {
                localStorage.setItem('email', user.email);
            }

            await GetUser()
                .then(async (userResp: IUserResponse) => {
                    localStorage.setItem("user", btoa(JSON.stringify(userResp.userDetail)));
                    localStorage.setItem("customer", JSON.stringify(userResp.userDetail.customerId));


                    dispatch(updateUserResponse(userResp));
                    await indexedDBService.setItem('User', 'userResp', userResp);
                    await GetCustomer()
                        .then(async (custResp: ICustomer) => {


                            let shouldUpdate = localStorage.getItem("formsPrimaryColor") === null ||
                                localStorage.getItem('formsPrimaryColor') !== custResp.primaryColor
                            localStorage.setItem("formsPrimaryColor", custResp.primaryColor)
                            localStorage.setItem("companyLogoUrl", custResp.cloudFileName)
                            let logoBase64 = await GetCustomerLogo(custResp.cloudFileName)
                            localStorage.setItem("logoBase64", logoBase64)
                            const branding: IBranding = {
                                primaryColor: custResp.primaryColor,
                                logoBase64: logoBase64,
                            };
                            dispatch(updateBranding(branding));
                            if (shouldUpdate) {
                                window.dispatchEvent(new Event("colorStorage"));
                            }
                            //await indexedDBService.setItem('User', 'userResp', userResp);
                        })
                        .catch(error => {
                            console.error('Error GetUser:', error);
                        });
                })
                .catch(error => {
                    console.error('Error GetUser:', error);
                });

            await GetUserPageAccess()
                .then(async (userPageAccessResponse: IUserPageAccessResponse) => {
                    dispatch(updateRoles({ userPageAccess: userPageAccessResponse.userPageAccess }));
                    await indexedDBService.setItem('User', 'userRoles', { userPageAccess: userPageAccessResponse.userPageAccess });
                })
                .catch(error => {
                    console.error('Error GetUserPageAccess:', error);
                });
            // navigate('/form-list')
        }

        const fetchFromIndexDb = async () => {

            const userState = await indexedDBService.getItem('User', 'updateUser');
            const userResp = await indexedDBService.getItem('User', 'userResp');
            const userRoles = await indexedDBService.getItem('User', 'userRoles');
            dispatch(updateUser(userState));
            dispatch(updateUserResponse(userResp));
            dispatch(updateRoles(userRoles));
        }

        const getExpirationDate = () => {
            const jwtToken = localStorage.getItem('auth0token');
            // if null somehow shows up in localstorage, i believe it is treated as a string here. therefore, testing === "null" too
            if (!jwtToken || jwtToken === "null") {
                return null;
            }
            const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
            return (jwt && jwt.exp && jwt.exp * 1000) || null;
        };

        const validateToken = () => {
            const exp = getExpirationDate();
            if (!exp) {
                return false;
            }

            return Date.now() < exp;
        };

        useEffect(() => {
            const fetchAndInit = async () => {
                try {
                    if (navigator.onLine) {

                        if (isLoading) {

                            return;
                        }
                        if (localStorage.getItem('auth0token')) {

                            if (validateToken()) {

                                if (userDetails != null) {

                                    if (userDetails.message === "") {

                                        initApplication(localStorage.getItem('auth0token'));
                                    }
                                }
                            }
                            else {

                                if (isAuthenticated) {

                                    const token = await getAccessTokenSilently();
                                    localStorage.setItem('auth0token', token);
                                    if (validateToken()) {

                                        if (userDetails != null) {

                                            if (userDetails.message === "") {

                                                initApplication(token);
                                            }
                                        }
                                    }
                                }
                                else {

                                    navigate('/login');
                                }
                            }
                        }
                        else {

                            try {
                                if (isAuthenticated) {

                                    const token = await getAccessTokenSilently();
                                    localStorage.setItem('auth0token', token);
                                    if (validateToken()) {

                                        if (userDetails != null) {

                                            await initApplication(token);
                                        }
                                    }
                                }
                                else {

                                    navigate('/login');
                                }

                            } catch (error) {
                                navigate('/login');
                            }
                        }
                    }
                    else {

                        fetchFromIndexDb();
                    }

                }
                catch (error) {
                    navigate('/login');

                }
            };
            fetchAndInit();
            //}, [isAuthenticated, isLoading, user]);
        }, []);

        if (userDetails != null) {
            if (userDetails.message === "") {
                return <ShoFormLoader />
            }
        }

        if (!isAuthenticated && !localStorage.getItem('auth0token')) {

            return <Navigate to={redirectTo} />;
        }
        return <WrappedComponent {...(props as P)} />;
    };
};

export default withProtectedRoute;

